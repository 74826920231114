<template>
  <v-icon :color="color">
    {{ icon }}
  </v-icon>
</template>

<script>
  export default {
    props: {
      state: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      color () {
        if (this.state === true) {
          return 'success'
        } else if (this.state === false) {
          return 'error'
        } else {
          return 'grey'
        }
      },
      icon () {
        if (this.state === true) {
          return 'check'
        } else if (this.state === false) {
          return 'highlight_off'
        } else {
          return 'help_outline'
        }
      }
    }
  }
</script>
