<messages>["../Zone"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="zone"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <template #basic>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="domainName"
            v-model.trim="filterParams.domainName"
            :label="$t ('filter.domainName')"/>
        </v-col>
        <template v-if="mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients)">
          <v-col cols="12" sm="6">
            <client-select
              ref="clientId"
              v-model="filterParams.clientId"
              show-inactive
              for-view
              nullable/>
          </v-col>
        </template>
      </v-row>
    </template>

    <template #additional>
      <v-row
        v-for="(date, idx) in filterParams.dates"
        :key="date.id">
        <v-col>
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                ref="dates"
                v-model="date.action"
                :label="$t ('filter.date.action.label')"
                :items="zoneActions"
                :error-messages="dateFilterActionErrors ($v.filterParams.dates, idx)"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="date.timeRel"
                :items="timeRelations"/>
            </v-col>
            <v-col cols="12" sm="5">
              <date-picker-field
                v-model="date.date"
                clearable
                type="date"
                :label="$t ('filter.date.label')"
                :error-messages="dateFilterDateErrors ($v.filterParams.dates, idx)"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="auto"
          align-self="center">
          <filter-delete-button
            :show-delete="filterParams.dates.length > 1"
            :disabled="!date.action && !date.date"
            @delete="onDeleteDate (date.id)"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn
            v-t="'filter.date.add'"
            small
            @click="onAddDate ()"/>
        </v-col>
      </v-row>

      <v-row class="shorten">
        <v-col cols="12" sm="6">
          <v-text-field
            ref="refId"
            v-model.trim="filterParams.refId"
            :label="$t ('filter.refId')"/>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            ref="type"
            v-model="filterParams.type"
            :items="[
              {text: $t ('filter.rstype.all'), value: 'all'},
              {text: $t ('filter.rstype.literal'), value: 'literal'},
              {text: $t ('filter.rstype.src'), value: 'source'}
            ]"
            :label="$t ('filter.resourcerecordtype')"/>
        </v-col>
      </v-row>

      <v-row class="shorten">
        <v-col cols="12">
          <name-server-select
            ref="nameserver"
            v-model="filterParams.nameserver"
            :label="$t ('filter.nameserver')"/>
        </v-col>
      </v-row>

      <v-row class="shorten">
        <v-col cols="12" md="4">
          <v-autocomplete
            ref="state"
            v-model="filterParams.state"
            :label="$t ('filter.state')"
            :items="zoneStates"/>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            ref="dnssec"
            v-model="filterParams.dnssec"
            :label="$t ('filter.dnssec')"
            :items="options"/>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            ref="autoLinkage"
            v-model="filterParams.autoLinkage"
            :label="$t ('filter.autoLinkage')"
            :items="options"/>
        </v-col>
      </v-row>
    </template>
  </search-filter-wrapper>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters} from 'vuex'
  import {requiredIf} from 'vuelidate/lib/validators'

  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'

  import DatePickerField from '@/app/core/components/DatePickerField'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import NameServerSelect from '@/app/core/components/NameServerSelect'

  import FilterDeleteButton
    from '@/app/core/components/Search/FilterDeleteButton'

  import SearchFilterWrapper
    from '@/app/core/components/Search/SearchFilterWrapper'

  /**
   * default zone search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    domainName: '',
    refId: '',
    nameserver: [],
    type: 'all',
    dnssec: 'any',
    autoLinkage: 'any',
    state: 'active',
    dates: [{id: 0, action: '', timeRel: 'on', date: null}],
    clientId: null
  })

  export default {
    name: 'ZoneFilter',

    components: {
      DatePickerField,
      FilterDeleteButton,
      ClientSelect,
      SearchFilterWrapper,
      NameServerSelect
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      InputHelper,
      QuickFilterMixin
    ],

    data () {
      return {
        basicFilter: ['domainName', 'clientId'],
        additionalFilterKeys: ['refId', 'nameserver', 'type', 'state', 'dates', 'dnssec', 'autoLinkage'],
        customHandlerKeys: ['nameserver'],
        maxDateId: 0
      }
    },

    validations: {
      filterParams: {
        dates: {
          $each: {
            action: {
              required: requiredIf (function (dateFilter) {
                return dateFilter.date
              })
            },
            date: {
              required: requiredIf (function (dateFilter) {
                return dateFilter.action
              })
            }
          }
        }
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayViewAllObjects',
        'mayViewSubEntitiesObjects',
        'hasSubClients'
      ]),

      zoneActions () {
        return ['create', 'update'].map (a => ({
          text: this.$t (`filter.date.action.${a}`),
          value: a
        }))
      },

      zoneStates () {
        return ['any', 'active', 'deleted'].map (a => ({
          text: this.$t (`filter.${a}`),
          value: a
        }))
      },

      options () {
        return ['any', 'on', 'off'].map (a => ({
          text: this.$t (`filter.states.${a}`),
          value: a
        }))
      },

      timeRelations () {
        return ['on', 'before', 'after'].map (r => ({
          text: this.$t (`filter.date.timeRel.${r}`),
          value: r
        }))
      }
    },

    watch: {
      'filterParams.dates' (newArray) {
        if (newArray.length === 0) {
          this.maxDateId = 0
          this.filterParams.dates.push ({
            id: this.maxDateId,
            action: '',
            timeRel: 'on',
            date: null
          })
        } else if (newArray.length === 1) {
          const emptyDateObject = {
            id: 0,
            action: '',
            timeRel: 'on',
            date: null
          }
          const newArrayEl = newArray[0]

          if (newArrayEl.action === emptyDateObject.action &&
            newArrayEl.timeRel === emptyDateObject.timeRel &&
            newArrayEl.date === emptyDateObject.date) {
            this.maxDateId = 0
            this.filterParams.dates[0].id = this.maxDateId
          }
        }
      }
    },

    methods: {
      onDeleteDate (id) {
        this.filterParams.dates.splice (
          this.getArrayIndex (this.filterParams.dates, id), 1)
      },

      onAddDate () {
        this.maxDateId++
        this.filterParams.dates.push ({
          id: this.maxDateId,
          action: '',
          timeRel: 'on',
          date: null
        })
      },

      getArrayIndex (array, id) {
        return array.findIndex (function (el) {
          return el.id === id
        })
      },

      dateFilterActionErrors (dateFilterObject, idx) {
        const errors = []

        if (!dateFilterObject.$each[idx].action.$dirty) {
          return errors
        }

        if (!dateFilterObject.$each[idx].action.required) {
          errors.push (this.$t ('required.date.action'))
        }

        return errors
      },

      dateFilterDateErrors (dateFilterObject, idx) {
        const errors = []

        if (!dateFilterObject.$each[idx].date.$dirty) {
          return errors
        }

        if (!dateFilterObject.$each[idx].date.required) {
          return [this.$t ('required.date.date')]
        }

        return errors
      },

      customQuickFilterHandler (key, value, qFItems) {
        value.forEach ((val, idx) => {
          const text = val
          const label = this.$t (`filter.${key}`)

          qFItems.push ({
            prop: key,
            idx,
            label,
            text
          })
        })
      }
    }
  }
</script>

<style scoped>
:global(.container .v-card .layout).shorten {
  margin-right: 28px;
}
</style>
