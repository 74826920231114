<messages>["./Zone"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '4'">
    <v-col cols="12" lg="8" xl="4">
      <zone-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>
    <v-col cols="12" xl="8">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        type="info"
        color="primary"/>
      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <zone-table
            v-bind="paginationState"
            :zone-data="resultList"
            :loading="isLoading"
            :rows-per-page-items="rowsPerPageItems"
            :total-count="totalCount"
            @delete="openConfirmDeleteDialog"
            @notify="onNotify"
            @shift="openShiftDialog"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"/>
        </v-card-text>
        <registry-object-delete-dialog
          v-if="deleteObject"
          v-model="isDeleteDialogVisible"
          :delete-object="deleteObject"
          @deleted="load"/>
        <simple-shift-dialog
          v-if="zoneObj"
          v-model="shiftDialog"
          type="zone"
          :shift-object="zoneObj"
          @shifted="load"/>
      </v-card>
    </v-col>
    <confirmation-dialog
      v-model="confirmation.isVisible"
      :headline="confirmation.headline"
      @ok="confirmation.callback">
      <div v-html="confirmation.text"/>
    </confirmation-dialog>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import RegistryObjectDeleteDialog
    from '@/app/core/components/RegistryObject/RegistryObjectDeleteDialog'
  import ZoneFilter from './components/ZoneFilter'
  import ZoneTable from './components/ZoneTable'

  import SearchPage from '@/app/core/mixins/SearchPage'

  import SimpleShiftDialog
    from '@/app/core/components/RegistryObject/SimpleShiftDialog'

  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'ZoneSearch',

    components: {
      CsvDownloadBtn,
      BaseLayout,
      ConfirmationDialog,
      RegistryObjectDeleteDialog,
      ZoneFilter,
      ZoneTable,
      SimpleShiftDialog
    },

    mixins: [SearchPage],

    data () {
      return {
        // this value is used by mixin for sending requests to the BE API
        OPERATION: 'zone/list',
        DELETE_OBJECT_STATIC_PROPS: {
          url: 'zone/delete',
          objectType: 'zone'
        },
        confirmation: {
          isVisible: false,
          headline: '',
          text: '',
          callback: () => {
          }
        },
        zoneObj: null,
        shiftDialog: false
      }
    },

    computed: {
      ...mapState ('filter', [
        'zoneSearchQuery'
      ])
    },

    methods: {
      // --- mutation, mapped from vuex ----------------------------------------
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        storeSearchQuery: 'filter/setZoneSearchQuery'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.zoneSearchQuery
      },

      /**
       * Display notification confirmation dialog
       *
       * @param refId     zone ID
       */
      onNotify (refId) {
        this.confirmation = {
          isVisible: true,
          headline: this.$t ('notify.confirmation.headline'),
          text: this.$t ('notify.confirmation.text'),
          callback: () => this.notify (refId)
        }
      },

      openShiftDialog (e) {
        this.zoneObj = {
          ...e
        }
        this.shiftDialog = true
      },

      /**
       * Activate notifications for given zone
       *
       * @param name        zone ID
       */
      notify (name) {
        this.confirmation.isVisible = false

        this.fetchData ({
          op: 'zone/notify',
          params: {name},
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('notify.success')
            )
          }
        })
      }
    }
  }
</script>
