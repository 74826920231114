<messages>["../Zone"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="zoneData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount">
    <template #item="props">
      <tr>
        <td>
          <router-link
            :to="route(props.item)"
            :class="{deletedItemLink: props.item.deleted}"
            v-text="props.item.refId"/>
        </td>
        <td v-if="!compactTable">
          {{ props.item.domainName }}
          <template v-if="props.item.domainName !== props.item.encodedDomainName">
            <br>
            {{ props.item.encodedDomainName }}
          </template>
        </td>
        <td v-if="!compactTable">
          {{ props.item.nameserver.length }}
        </td>
        <td v-if="!veryCompactTable">
          {{ props.item.type }}
        </td>
        <td v-if="(mayViewAllObjects || mayViewSubEntitiesObjects) && !veryCompactTable">
          <client-link :id="props.item.clientId"/>
        </td>
        <td v-if="!compactTable">
          {{ formatDateShort(props.item.creation, true) }}
        </td>
        <td v-if="!compactTable && !(mayViewAllObjects || mayViewSubEntitiesObjects)">
          {{ formatDateShort(props.item.update, true) }}
        </td>
        <td v-if="!compactTable">
          <state-icon :state="props.item.dnssec"/>
        </td>
        <td v-if="!compactTable">
          <state-icon :state="props.item.autoLinkage"/>
        </td>
        <td>
          <action-buttons
            :value="isActionButtonsActive (props.item.refId)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.refId) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import StateIcon from '@/app/core/components/StateIcon'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  import {mapGetters} from 'vuex'

  export default {
    name: 'ZoneTable',

    components: {
      ActionButtons,
      ClientLink,
      SearchTableNoData,
      StateIcon
    },

    mixins: [paginationMixins, actionButtonsHelper],

    props: {
      zoneData: {
        type: Array,
        default: () => []
      },
      totalCount: {type: Number, default: 0},
      rowsPerPageItems: {
        type: Array,
        default: undefined
      },
      loading: Boolean
    },

    computed: {
      ...mapGetters ('auth', [
        'mayViewAllObjects',
        'mayViewSubEntitiesObjects',
        'mayManageObject',
        'hasSubClients',
        'mayManageForeignObjects'
      ]),

      compactTable () {
        return this.$vuetify.breakpoint.smAndDown
      },

      veryCompactTable () {
        return this.$vuetify.breakpoint.xs
      },

      headers () {
        return [
          {
            text: this.$t ('list.header.id'),
            value: 'refId'
          },
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.zonename'),
              value: 'domainName'
            }],
          ...(this.compactTable)
            ? []
            : [{
              text: this.$t ('list.header.nscount'),
              value: 'nameserver',
              sortable: false
            }],
          ...this.veryCompactTable
            ? []
            : [{
              text: this.$t ('list.header.rstype'),
              value: 'type',
              sortable: false
            }],
          ...(
            !(this.mayViewAllObjects || this.mayViewSubEntitiesObjects) ||
            this.veryCompactTable
              ? []
              : [{
                text: this.$t ('list.header.owner'),
                value: 'clientId'
              }]),
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.creation'),
              value: 'creation'
            }],
          ...(
            this.compactTable || this.mayViewAllObjects ||
            this.mayViewSubEntitiesObjects
              ? []
              : [{
                text: this.$t ('list.header.update'),
                value: 'update'
              }]),
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.dnssec'),
              value: 'dnssec'
            }],
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.linkage'),
              value: 'autoLinkage'
            }],
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      }
    },

    methods: {

      getActionButtons (item) {
        const view = [{
          action: 'view',
          id: item.id,
          item: item,
          icon: 'visibility',
          tooltip: this.$t ('general.button.view')
        }]

        const edit = [{
          action: 'edit',
          registry: item.registryType,
          handle: item.refId,
          zoneVersionId: item.versionId,
          zoneDeleted: item.deleted,
          icon: 'edit',
          disabled: item.deleted || !(this.mayManageObject (item.clientId)),
          tooltip: this.$t ('general.button.edit')
        }]

        const notify = [...(item.dnssec && !item.autoLinkage && !item.deleted
          ? [{
            action: 'notify',
            registry: item.registryType,
            handle: item.refId,
            icon: 'add_alert',
            tooltip: this.$t ('notify.action')
          }]
          : [])]

        const deleteAction = [{
          action: 'delete',
          actionArg: [item],
          zoneVersionId: item.versionId,
          name: item.domainName,
          handle: item.refId,
          icon: 'delete',
          tooltip: this.$t ('general.button.delete'),
          disabled: item.deleted || !(this.mayManageObject (item.clientId)),
          color: 'error'
        }]

        const shift = [...this.mayManageForeignObjects && this.hasSubClients
          ? [{
            action: 'shift',
            actionArg: [item],
            icon: 'swap_vert',
            tooltip: this.$t ('general.button.shift'),
            handle: item.refId,
            zoneVersionId: item.versionId,
            zoneId: item.id,
            disabled: item.deleted || item.pendingDeletion ||
              !(this.mayManageObject (item.clientId))
          }]
          : []
        ]

        return [
          ...view,
          ...edit,
          ...notify,
          {divider: true},
          ...deleteAction,
          {divider: true},
          ...shift
        ]
      },

      processActionButton (button) {
        console.log (button)
        switch (button.action) {
          case 'view':
            this.$router.push (this.route (button.item))
            break
          case 'shift':
            this.$emit (
              'shift',
              {
                id: button.zoneId,
                name: button.handle
              }
            )
            break
          case 'edit':
            this.$router.push ({
              name: 'zone.edit',
              params: {zoneId: button.handle}
            })
            break
          case 'notify':
            this.$emit (
              'notify',
              button.handle
            )
            break
          case 'delete':
            this.$emit (
              'delete',
              {
                v_id: button.zoneVersionId,
                refId: button.handle,
                name: button.name
              }
            )
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      },

      formattedDate (value) {
        return !value ? '' : this.formatDateShort (value)
      },

      route (zone) {
        if (zone.deleted) {
          return {
            name: 'zone.view.version',
            params: {vid: zone.versionId}
          }
        } else {
          return {
            name: 'zone.view',
            params: {name: zone.refId}
          }
        }
      }
    }
  }
</script>
